import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { SubClient } from 'src/app/models/client.model';
import { ObjectsRequest } from 'src/app/models/console.request.model';
import { FilterAndPagBean } from 'src/app/models/FilterAndPagBean';
import { UsersConsoleRequest } from 'src/app/models/usersConsoleRequest.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class SubClientService {
  
  constructor(
    private merchantRest: RestService, 
    private authService:AuthService
  ) {}

  async getListed(filterAndPagBean:FilterAndPagBean){
    const _body = new UsersConsoleRequest;
    _body.filterAndPagBean = filterAndPagBean;
    let userSession = this.authService.currentUserValue();
    if(userSession){
      _body.idUser = userSession.id;
      _body.idOperator = userSession.operator?.id;
      _body.idEntorno = userSession.entorno?.id;
    }

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListSubClient/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async find(filterAndPagBean:FilterAndPagBean){
    const _body = new UsersConsoleRequest;
    _body.filterAndPagBean = filterAndPagBean;
    let userSession = this.authService.currentUserValue();
    if(userSession){
      _body.idUser = userSession.id;
      _body.idOperator = userSession.operator?.id;
      _body.idEntorno = userSession.entorno?.id;
    }

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'getListSubClientCompleta/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async countTotal(filterAndPagBean:FilterAndPagBean){    
    const _body = new UsersConsoleRequest;
    _body.filterAndPagBean = new FilterAndPagBean(filterAndPagBean.orderSentido,filterAndPagBean.order,filterAndPagBean.filterGeneric,null,null,null);

    let userSession = this.authService.currentUserValue();
    if(userSession){
      _body.idUser = userSession.id;
      _body.idOperator = userSession.operator?.id;
      _body.idEntorno = userSession.entorno?.id;
    }

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'countSubClient/',true,true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async getItem(id:number){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'getSubClient/',true,true )   
      .catch(err => {
        return null
      })
      
    return value?.data;   
  }
  
  async delete(id:number){
    let value = await this.merchantRest.commonRestCallConsolaRest({ id },'deleteSubClient/', true, true)   
      .catch(err => {
        return null
      })
      
    return value;   
  }

  async addUpdate(_body:SubClient){
    let userSession = this.authService.currentUserValue();
    if(userSession) _body.idEntorno = userSession.entorno?.id as number;

    let value = await this.merchantRest.commonRestCallConsolaRest(_body,'addSubClient/',true,true )   
      .catch(err => {
        return null
      })
      
    return value;   
  }

     async findFileToExport(filterAndPagBean: FilterAndPagBean){
    
        let _body: ObjectsRequest = new ObjectsRequest();
        _body.filterAndPagBean = filterAndPagBean;
        
        //getListVendingMachineCoordenadas
        let value = await this.merchantRest.commonRestCallFile(_body,'getFileListLevel3',true,true )   
          .catch(err => {
            return null
          })
          
        return value;   
      }

}