import { inject, Injectable } from "@angular/core";
import { OperatorService } from "../pages/admin/operator/operator.service";
import { ClientService } from "../pages/admin/client/client.service";
import { SubClientService } from "../pages/admin/sub-client/sub-client.service";
import { FilterAndPagBean } from "../models/FilterAndPagBean";
import { BehaviorSubject } from "rxjs";
import { Jerarquia4Service } from "../pages/admin/jerarquia4/jerarquia4.service";
import { Jerarquia5Service } from "../pages/admin/jerarquia5/jerarquia5.service";
import { HierarchyLevelData, HierarchyLevelType } from "../models/hierarchyBase.model";


@Injectable({
    providedIn: 'root',
})
export class HierarchyOptionsService {
    operators = inject(OperatorService);
    clients = inject(ClientService);
    subclients = inject(SubClientService);
    level4 = inject(Jerarquia4Service);
    level5 = inject(Jerarquia5Service);

    private _hierarchyMap = new Map<HierarchyLevelType, any>();
    private hierarchyState$: BehaviorSubject<Map<HierarchyLevelType, any>> = new BehaviorSubject(this._hierarchyMap);

    load(){
        this.getLevel1();
        this.getLevel2();
        this.getLevel3();
        this.getLevel4();
        this.getLevel5();
    }

    updateLevelList(level:HierarchyLevelType){
        switch(level){
            case('LEVEL_1'):
                this.getLevel1();
                break;
            case('LEVEL_2'):
                this.getLevel2();
                break;
            case('LEVEL_3'):
                this.getLevel3();
                break;
            case('LEVEL_4'):
                this.getLevel4();
                break;
            case('LEVEL_5'):
                this.getLevel5();
                break;
        }
    }

    cleanState(){
        this._hierarchyMap.clear();
        this.hierarchyState$.next(this._hierarchyMap);
    }
    
    private async getLevel1(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.operators.find(_request);
        if (response && response.data) {
            this.setLevelOption('LEVEL_1',response.data);
        }
    }

    private async getLevel2(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.clients.find(_request);
        if (response && response.data) {
            this.setLevelOption('LEVEL_2',response.data);
        }
    }

    private async getLevel3(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.subclients.find(_request);
        if (response && response.data) {
            this.setLevelOption('LEVEL_3',response.data);
        }
    }

    private async getLevel4(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.level4.find(_request);
        if (response && response.data) {
            this.setLevelOption('LEVEL_4', response.data);
        }
    }

    private async getLevel5(): Promise<void> {
        const _request = new FilterAndPagBean(null,null, [], null, null, null);
        const response = await this.level5.find(_request);
        if (response && response.data) {
            this.setLevelOption('LEVEL_5',response.data);
        }
    }


    public setLevelOption(level:HierarchyLevelType, data:HierarchyLevelData[]){
        const options =  new Map();
        data.forEach((item:HierarchyLevelData) => (options.set(item.id, item)));
        this._hierarchyMap.set(level, options);
        this.hierarchyState$.next(this._hierarchyMap);
    }


    public get state() {
        return this.hierarchyState$;
    }

    public getFilteredLevelToOptions(level:string, filterBy?: string, filterValue?:number):{ label:string, value:number }[]{
        if(!this._hierarchyMap.get(level as HierarchyLevelType)) return [];
        return Array.from(this._hierarchyMap.get(level as HierarchyLevelType).values())
            .filter((item:any)=>{
                if(filterBy){
                    const [fieldParend, fieldChild] = filterBy.split('.');
                    const compareValue = fieldChild ? item[fieldParend][fieldChild] : item[fieldParend];
                    return compareValue === filterValue;
                }  
                return true;
            })
            .map((item:any)=> ({ label: item.name, value:item.id })); 
    }

    public getLevelOption(level:string, itemId:number): HierarchyLevelData | null{
        if(!this._hierarchyMap.get(level as HierarchyLevelType)) return null;
        const item = Array.from<HierarchyLevelData>(this._hierarchyMap.get(level as HierarchyLevelType).values())
            .filter((item:any)=> (item.id === itemId)).pop();
        return item ?? null; 
    }
}
